.App {
    text-align: center;
    padding-bottom: 40px;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

button:focus {
    outline: none;
}

.App-link {
    color: #09d3ac;
}

a {
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
}

img {
    max-width: 100%;
}


/*
body {
    background: #f8f8f8;
}
*/


/*
#root::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    background: rgba(35, 54, 161, 1);
    background: -moz-linear-gradient(left, rgba(35, 54, 161, 1) 0%, rgba(80, 151, 218, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(35, 54, 161, 1)), color-stop(100%, rgba(80, 151, 218, 1)));
    background: -webkit-linear-gradient(left, rgba(35, 54, 161, 1) 0%, rgba(80, 151, 218, 1) 100%);
    background: -o-linear-gradient(left, rgba(35, 54, 161, 1) 0%, rgba(80, 151, 218, 1) 100%);
    background: -ms-linear-gradient(left, rgba(35, 54, 161, 1) 0%, rgba(80, 151, 218, 1) 100%);
    background: linear-gradient(to right, rgba(35, 54, 161, 1) 0%, rgba(80, 151, 218, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#2336a1', endColorstr='#5097da', GradientType=1);
    height: 380px;
    z-index: -1;
}*/

.MuiDialog-scrollPaper {
    align-items: baseline !important;
}