
.Footer{
text-align: center;
left: 0;
bottom: 0;
right: 0;
background: #4c4c4c;
left: 0;
bottom: 0;
color: #c3c3c3;
right: 0;
}